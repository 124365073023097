<template>
  <div class="list_item_area">
    <!-- 新闻 -->
    <div class="news_list">
      <div class="news_list_text_wrap">
        <div class="news_list_title">
          {{repair.title}}
        </div>
        <div class="news_list_tip">
          <div class="news_time">{{repair.createTime}}</div>
        </div>
      </div>
      <van-image width="100" height="75" fit="cover" :src="repair.image" />
    </div>
  </div>

</template>
<script>

export default {

  props: {
    repair: {
      type: Object,
      required: true
    },
  },
  components: {},

	data() {
		return {


		};
	},
  created() {},
	mounted() {},
	methods: {



	}
};
</script>
<style lang="scss" scoped>
.page_area {
	background-color: #eeeeee;
}
// 顶部的显示区域
.top_area {
	height: 178px;
	padding: 15px;
	color: #ffffff;
	background-color: #1eaa72;
	// background-image: url(img/top_area_bg.41a85a6f.png);
	background-position: left center;
	background-size: 100% auto;
	background-repeat: no-repeat;
	.top_center {
		margin-top: 52px;
		width: 100%;
		height: 72px;
		display: flex;
		position: relative;
		flex: 1;
		.top_center_text {
			margin: 8px 0;
			margin-left: 16px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			flex: 1;
		}
		.tip {
			position: absolute;
			right: 0;
			top: -10px;
			width: 50px;
			height: 30px;
			font-size: 10px;
		}
	}
}
.grid_area {
	margin: -38px 14px 11px 14px;
	border-radius: 10px;
}
// 轮播图
.swipe_wrap {
	height: 147px;
	margin: 0 14px;
	border-radius: 8px;
	.swipe_item {
		width: 100%;
		height: 100%;
	}
}
// 垂直广告
.notice-swipe {
	height: 40px;
	line-height: 40px;
}
.divider {
	width: 50%;
	margin: 0 auto;
}
.news_list {
	background-color: #ffffff;
	display: flex;
	padding: 15px 15px 20px 15px;
	.news_list_text_wrap {
		width: 100%;
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin: 0 4px;
		border-radius: 4px;
		.news_list_title {
			color: #051b28;
			overflow: hidden;
			display: -webkit-box;
			text-overflow: ellipsis;
			-webkit-line-clamp: 2; /*要显示的行数*/
			-webkit-box-orient: vertical;
			font-size: 18px;
		}
		.news_list_tip {
			display: flex;
			align-items: center;
			width: 100%;
			justify-content: space-between;
			.news_tip {
				background-color: #aee7d0;
				color: #ffffff;
				font-size: 10px;
				padding: 3px 5px;
			}
			.news_time {
				color: #b2b2b2;
				font-size: 14px;
			}
		}
	}
}
.news_list + .news_list {
	border-top: 1px solid #e5e5e5;
}


.list_item_area {
  background: #ffffff;
  border-top: 6px solid #efefef;
  width: 100%;
}


</style>
