<template>
	<div class="page_area">
    <!--  分类标题  -->
    <van-sticky>
		<van-tabs v-model="active" @click="onClick" color="#3FB675" title-active-color="#3FB675">
			<van-tab :name="item.value" :title="item.text" v-for="item in noticeCategoryAry" :key="item.value"></van-tab>
		</van-tabs>
    </van-sticky>

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-empty description="暂无任何内容~" style="height:500px" :image="require('@/assets/image/nothing.png')" v-show="list.length == 0" />
      <van-list v-model="loading" v-show="list.length != 0" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <list-item @click.native="onDetails(item)" v-for="item in list" :key="item.uuid" :repair="item"></list-item>
      </van-list>
    </van-pull-refresh>

	</div>
</template>
<script>

import listItem from '@/views/notice/list-item.vue';
import { initDictOptions } from '@/api/login.js';
import { zmNoticeList } from '@/api/repairs.js';

export default {
  props: {},
  components: { listItem },
	data() {
		return {
			active: 0,
      noticeCategoryAry:[], //分类字段数组
      list: [],
      finished: false,
      loading: false,
      refreshing: false,
      queryParams: {
        order: 'desc',
        pageNo: 1,
        pageSize: 10,
        queryField: [
          {
            field: 'category',
            queryType: '',
            type: '',
            value: ''
          }
        ],
        sort: 'createTime'
      }
		};
	},
  created() {
    this.getNoticeCategory(); // 新增分类
  },
	mounted() {},
	methods: {

		onDetails(item) {
			this.$router.push({
				name: 'noticeDetails',
        query: {
          uuid: item.uuid
        }
			});
		},

    //新闻分类触发事件
		onClick(name, title) {
			console.log(name, title);
      this.list = [];
			//查询条件
			this.queryParams.queryField = [
        {
          field: 'category',
          queryType: '',
          type: '',
          value: name.toString()
        }
      ]
      this.getZmNoticeList()

		},


    //获取新闻
    getZmNoticeList(){

      zmNoticeList(this.queryParams).then(res => {
        if (res.code == 200) {

          this.list=res.rows
          if (this.queryParams.pageNo >= res.totalPage) {
            this.finished = true;
          }
        }else {
          this.$toast.fail(res.message);
        }
      }).finally(() => {
        this.loading = false;
        this.refreshing = false;
      });
    },

    // 获取新闻分类字典
    getNoticeCategory() {
      initDictOptions('zm_notice_category').then(res => {
        const ary = [];
        res.forEach(item => {
          ary.push({
            text: item.text,
            value: item.value
          });
        });
        this.noticeCategoryAry = ary;
        this.onClick(ary[0].value,ary[0].text) //初次触发获取新闻
      });
    },

    onRefresh() {
      console.log('onRefresh');
      // 下拉刷新，重置数据
      this.list = [];
      this.finished = false;
      this.queryParams.pageNo = 0;
      this.onLoad();
    },
    onLoad() {
      console.log('onLoad');
      // vant-list获取数据
      this.loading = true;
      this.queryParams.pageNo = this.queryParams.pageNo + 1;
      this.getZmNoticeList();
    },

	}
};
</script>
<style lang="scss" scoped>
.page_area {
  background-color: #efefef;
  min-height: 100vh;
}
// 顶部的显示区域
.top_area {
	height: 178px;
	padding: 15px;
	color: #ffffff;
	background-color: #1eaa72;
	// background-image: url(img/top_area_bg.41a85a6f.png);
	background-position: left center;
	background-size: 100% auto;
	background-repeat: no-repeat;
	.top_center {
		margin-top: 52px;
		width: 100%;
		height: 72px;
		display: flex;
		position: relative;
		flex: 1;
		.top_center_text {
			margin: 8px 0;
			margin-left: 16px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			flex: 1;
		}
		.tip {
			position: absolute;
			right: 0;
			top: -10px;
			width: 50px;
			height: 30px;
			font-size: 10px;
		}
	}
}
.grid_area {
	margin: -38px 14px 11px 14px;
	border-radius: 10px;
}
// 轮播图
.swipe_wrap {
	height: 147px;
	margin: 0 14px;
	border-radius: 8px;
	.swipe_item {
		width: 100%;
		height: 100%;
	}
}
// 垂直广告
.notice-swipe {
	height: 40px;
	line-height: 40px;
}
.divider {
	width: 50%;
	margin: 0 auto;
}
.news_list {
	background-color: #ffffff;
	display: flex;
	padding: 15px 15px 20px 15px;
	.news_list_text_wrap {
		width: 100%;
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin: 0 4px;
		border-radius: 4px;
		.news_list_title {
			color: #051b28;
			overflow: hidden;
			display: -webkit-box;
			text-overflow: ellipsis;
			-webkit-line-clamp: 2; /*要显示的行数*/
			-webkit-box-orient: vertical;
		}
		.news_list_tip {
			display: flex;
			align-items: center;
			width: 100%;
			justify-content: space-between;
			.news_tip {
				background-color: #aee7d0;
				color: #ffffff;
				font-size: 10px;
				padding: 3px 5px;
			}
			.news_time {
				color: #b2b2b2;
				font-size: 10px;
			}
		}
	}
}
.news_list + .news_list {
	border-top: 1px solid #e5e5e5;
}
</style>
